import React, { useState } from "react";
import "./forgot-password.css";
import { useNavigate } from "react-router-dom";
import { useCreateEmailMutation } from "../../../features/forgot-password/forgotPasswordApiSlice";

const ForgotPassword = () => {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email:", email);
  };

  return (
    <div className="forgot-password-form-container">
      <h2>Recuperar Contraseña</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="form-label-forgot-password" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input-forgot-pasword"
            required
          />
        </div>
        <button type="submit" className="form-button-forgot-password">
          Enviar Enlace
        </button>
      </form>
      <p style={{ width: "100%", textAlign: "right" }}>
        <span
          className="login-link"
          onClick={() => {
            navigation("/login");
          }}
        >
          Volver a Iniciar Sesión
        </span>
      </p>
    </div>
  );
};

export { ForgotPassword };
