// Plans.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./plans.css";
import back from "../../assets/icons/go-back.svg";
import { useGetSubscriptionPlansQuery } from "../../features/stripe/stripeApiSlice";

const getCSSVariable = (variable) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable);

const Plans = ({ subscription }) => {
  const navigate = useNavigate();
  const { data: subscriptionData } = useGetSubscriptionPlansQuery();
  // Convertir precio de Stripe de centavos a moneda completa
  const formatPrice = (amount) => amount / 100;

  //Extract priceId from the passed prop
  const priceId = subscription?.plan?.id;

  // Crear array de planes desde datos de Stripe
  const plans =
    subscriptionData?.data.products.map((product) => ({
      plan_name: product.name,
      price: formatPrice(product.default_price.unit_amount),
      description: product.description || "Subscription plan",
      descriptionComplete: `
      *${product.name} Membership
      *Main Features
      -Access to platform
      -Customer support
      *Monthly subscription
      *Regular updates`,
      priceId: product.default_price.id,
      productId: product.id,
    })) || [];

  const handleNavigation = (path, planData) => {
    const queryParams = new URLSearchParams({
      priceId: planData.priceId,
      planName: planData.plan_name,
    });

    navigate(`${path}?${queryParams.toString()}`, {
      state: {
        ...planData,
        subscriptionData: subscriptionData?.data,
        validateSubscription: subscription,
      },
    });
  };

  const formatDescription = (description) => {
    const lines = description.trim().split("\n");
    const formattedList = [];
    let subList = [];

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();
      if (trimmedLine.startsWith("*")) {
        if (subList.length > 0) {
          formattedList.push(
            <ul key={`sub-${index}`}>
              {subList.map((item, subIndex) => (
                <li key={subIndex}>{item}</li>
              ))}
            </ul>
          );
          subList = [];
        }
        formattedList.push(<li key={index}>{trimmedLine.slice(1).trim()}</li>);
      } else if (trimmedLine.startsWith("-")) {
        subList.push(trimmedLine.slice(1).trim());
      }
    });

    if (subList.length > 0) {
      formattedList.push(
        <ul key="last-sub">
          {subList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }

    return formattedList;
  };

  const [isWide, setIsWide] = useState(window.innerWidth > 480);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="main-plans">
      <div onClick={() => handleNavigation("/home")} className="go-back-plans">
        <img src={back} alt="" />
      </div>
      <div className="text-plans">
        Encuentra el plan perfecto para ti y <br /> para todo tu equipo.
      </div>
      <div className="plans-text">
        <label>Nuestros Planes</label>
        <p>Encuentra el plan perfecto para ti y para tu empresa</p>
      </div>
      <div>
        <div className="subscription-section">
          {plans.map((plan, index) => (
            <div key={index} className="plan-card">
              <div className="subscription-cost">
                <label>{plan.plan_name}</label>
                <p>
                  ${plan.price}
                  <span>/mes</span>
                </p>
              </div>
              <div
                className="get-subscription"
                style={{
                  backgroundColor: isWide
                    ? getCSSVariable("--background-step-two")
                    : getCSSVariable("--background-step"),
                }}
              >
                <p className="description-movil">{plan.description}</p>
                <p className="description-tablet-compu">
                  <span>Incluye</span>
                  <br />
                  <ul>{formatDescription(plan.descriptionComplete)}</ul>
                </p>
                <button
                  style={{
                    backgroundColor:
                      priceId === plan.priceId ? "gray" : "#1cd93c", // Gray if matched, green otherwise
                    color: priceId === plan.priceId ? "#ffffff" : "#fafafa",
                    cursor:
                      priceId === plan.priceId ? "not-allowed" : "pointer",
                  }}
                  onClick={() =>
                    handleNavigation("/home/purchase-detail", plan)
                  }
                  disabled={priceId === plan.priceId ? true : false}
                >
                  {priceId === plan.priceId
                    ? "Plan Actual"
                    : `Conseguir ${plan.plan_name}`}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Plans };
