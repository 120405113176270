import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import { BaseView } from "./components/base-view/BaseView";
import { NavBar } from "./components/nav-bar/NavBar";
import { SideBar } from "./components/side-bar/SideBar";
import Footer from "./components/footer/Footer";

const breadcrumbMap = {
  "/home": "Inicio",
  "/home/profile": "Mi Perfil",
  "/home/profile/company-location": "Direccion de la Empresa",
  "/home/profile/login-and-security": "Inicio de sesion y Seguridad",
  "/home/profile/advanced-configuration": "Configuracion Avanzada",
  "/home/profile/advanced-configuration/rules": "Reglas de viaje",
  "/home/profile/my-payments": "Mis Pagos",
  "/home/profile/billing-information": "Ajustes",
  "/home/profile/payment-history": "Ajustes",
  "/home/profile/trip-rules": "Reglas de Viaje",
  "/home/plans": "Planes",
  "/home/purchase-detail": "Detalles de Compra",
  "/home/payment-method": "Metodo de Pago",
  "/home/subscription-payment-history": "Historial de Subscripcion",
  "/home/new-payment-method": "Nuevo Metodo de Pago",
  "/employees": "Empleados",
  "/employees/register-employee": "Registro de Empleado",
  "/employees/edition-employee": "Edición de Empleado",
  "/employees/manager-employee": "Empleados",
  "/subscriptions": "Mi Suscripción",
  "/payments": "Mis pagos",
  "/paper-bin": "Papelera",
};

const generateBreadcrumbs = (pathname) => {
  const pathArray = pathname.split("/").filter(Boolean);
  let path = "";
  return pathArray.map((segment, index) => {
    path += `/${segment}`;
    return { path, label: breadcrumbMap[path] || segment };
  });
};

function App() {
  const location = useLocation();
  const [sideBarVisible, setSideBarVisible] = useState(false);

  const toggleSideBar = (visible) => {
    setSideBarVisible(visible);
  };

  const isAuthRoute = ["/login", "/sign-up", "/forgot-password", "/"].includes(
    location.pathname
  );

  const breadcrumbs = generateBreadcrumbs(location.pathname);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isAuthRoute && (
        <NavBar
          breadcrumbs={breadcrumbs}
        />
      )}
      {!isAuthRoute ? (
        <>
          <div
            className={`container-main ${sideBarVisible ? "show-sidebar" : ""}`}
          >
            <SideBar toggleSideBar={toggleSideBar}
              sideBarVisible={sideBarVisible}
            />
          </div>
          {sideBarVisible && (
            <div className="overlay" onClick={() => toggleSideBar(false)}></div>
          )}
        </>
      ) : (
        <BaseView />
      )}
      {windowWidth > 480 && !isAuthRoute && <Footer />}
    </>
  );
}

export default App;
