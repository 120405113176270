import React, { useState } from "react";
import PropTypes from "prop-types";
import "./toogle-button-styles.css";

const ToggleButton = ({ initialState }) => {
    const [isToggled, setIsToggled] = useState(initialState);

    const handleToggle = () => {
        setIsToggled((prevState) => !prevState);
    };

    return (
        <div
            className={`toggle-container ${isToggled ? "on" : "off"}`}
            onClick={handleToggle}
        >
            <div className="toggle-circle"></div>
        </div>
    );
};

ToggleButton.propTypes = {
    initialState: PropTypes.bool,
};

ToggleButton.defaultProps = {
    initialState: false,
};

export { ToggleButton };
