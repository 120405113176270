import React from "react";
import "./green-border-button-styles.css";

const GreenBorderButton = ({ text, border, onClick }) => {
  return (
    <button
      className="change-photo-button-settings"
      style={{ borderColor: border ? "#1cd93c" : "#fff" }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export { GreenBorderButton };
