// features/auth/authSlice.js
// Manejo del estado de autenticación
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  email: null,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    logOut: (state) => {
      state.accessToken = null;
      state.email = null;
      state.user = null;
    },
    setEmailToVerify: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { setAccessToken, logOut, setEmailToVerify } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.accessToken;
export const selectCurrentEmail = (state) => state.auth.email;
