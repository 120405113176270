import { React, useState, useCallback } from "react";
import "./subscription-styles.css";
import rightArrowIcon from "../../assets/icons/right.svg";
import cardIcon from "../../assets/icons/wallet-outline.svg";
import greenCheckIcon from "../../assets/icons/green-check-icon.svg";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Plans } from "../../components/plans/Plans";
import {
  useGetSubscriptionQuery,
  useCancelSubscriptionMutation,
  useGetDefaultPaymentMethodQuery,
} from "../../features/stripe/stripeApiSlice";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const Subscription = () => {
  const widthWindow = useWindowWidth();
  const navigate = useNavigate();

  // State to toggle between subscription details and plans
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false); // For the modal
  const [modalType, setModalType] = useState(null); // "success" or "error"

  // Function to open the modal and set its type
  const openModal = useCallback((type) => {
    setIsOpen(true);
    setModalType(type);
    // Redirect after 3 seconds if it's a success modal
    setTimeout(() => {
      if (modalType === "success") {
        // You might want to redirect to a different page after cancellation
        navigate("/home");
      }
    }, 3000);
  }, []);

  // Function to close the modal
  const closeModal = useCallback(() => setIsOpen(false), []);

  //Get subscription data from the user.
  const { data: subscriptionData, isLoading: isSubscriptionLoading } =
    useGetSubscriptionQuery();

  // Fetch default payment method
  const {
    data: paymentMethodData,
    isLoading: isPaymentMethodLoading,
    error: paymentMethodError,
  } = useGetDefaultPaymentMethodQuery();

  //Cancel subscription
  const [cancelSubscription] = useCancelSubscriptionMutation();

  // Extract Subscription Details
  const subscription = subscriptionData?.subscription;
  const hasSubscription = Boolean(subscription);
  const planName =
    subscription?.items?.data?.[0]?.price?.nickname || "Plan Básico";
  const monthlyPrice =
    Math.floor(subscription?.items?.data?.[0]?.price?.unit_amount / 100) || 0;
  const nextPaymentDate = subscription?.current_period_end
    ? new Date(subscription.current_period_end * 1000).toLocaleDateString()
    : undefined;

  // Extract payment method details
  const paymentMethod = paymentMethodData?.paymentMethod;
  const cardBrand = paymentMethod?.brand || "Unknown";
  const name = paymentMethod?.name || "No hay nombre del titular";
  const last4 = paymentMethod?.last4 || "****";
  const expMonth = paymentMethod?.exp_month || "MM";
  const expYear = paymentMethod?.exp_year || "YY";

  // Handle Cancel Subscription
  const handleCancelSubscription = async () => {
    if (!subscription || !subscription.id) {
      alert("No hay suscripción activa para cancelar.");
      return;
    }

    try {
      await cancelSubscription(subscription.id).unwrap();
      openModal("success");
    } catch (error) {
      console.error("Error al cancelar la suscripción:", error);
      alert("Error al cancelar la suscripción. Inténtalo nuevamente.");
    }
  };

  // Render plans if user is changing the plan
  if (isChangingPlan) {
    return <Plans subscription={subscription} />;
  }

  if (isSubscriptionLoading || isPaymentMethodLoading) {
    return <div className="loading-state">Cargando...</div>;
  }

  if (paymentMethodError) {
    console.error("Error fetching payment method:", paymentMethodError);
  }

  return (
    <>
      {hasSubscription ? (
        <div
          className="subscription-page-container"
          style={{ width: widthWindow > 1300 ? 1300 : "100%" }}
        >
          <div className="plan-card">
            <div className="subscription-cost">
              <label>{planName}</label>
              <p>
                ${monthlyPrice.toFixed(0)}
                <span>/mes</span>
              </p>
            </div>
            <div className="get-subscription my-subscription">
              <p className="description-movil">Descripción del plan.</p>
              <p className="description-tablet-compu">
                <span>Incluye:</span>
                <br />
                <ul>
                  <li>Acceso completo</li>
                  <li>Actualizaciones regulares</li>
                  <li>Soporte al cliente</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="payment-details">
            <div className="detail-and-price">
              <label>Detalles</label>
              <div className="cost-and-plan-name">
                <label>{planName} Mensual</label>
                <label>${monthlyPrice.toFixed(2)} MXN</label>
              </div>
              <div className="monthly-payment">
                <span>Próximo pago: {nextPaymentDate}</span>
              </div>
            </div>
            <div className="payment-method">
              <label>Método de Pago</label>
              <button
                onClick={() =>
                  navigate("/home/payment-method", {
                    state: {
                      subscription: subscription,
                    },
                  })
                }
              >
                <div className="payment-card-data">
                  <img src={cardIcon} alt="Tarjeta" />
                  <div>
                    <label>
                      <strong>{cardBrand} |</strong>
                      {name}
                    </label>
                    <div className="card-number-and-expiration-date">
                      <span>....{last4}</span>
                      <span>
                        {expMonth}/{expYear}
                      </span>
                    </div>
                  </div>
                </div>
                <img src={rightArrowIcon} alt="right arrow icon" />
              </button>
              <div className="continue-with-payment subscription-buttons-container">
                <button onClick={handleCancelSubscription}>
                  Cancelar Suscripción
                </button>
                <button onClick={() => setIsChangingPlan(true)}>
                  Cambiar Plan
                </button>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modalContent"
            overlayClassName="modalOverlay"
            contentLabel="Subscription Update Modal"
          >
            {modalType === "success" && (
              <>
                <img src={greenCheckIcon} alt="checkout" />
                <h3>Subscription Cancelled!</h3>
                <p>Your subscription has been cancelled successfully.</p>
              </>
            )}
            {modalType === "error" && (
              <>
                <h3>Error cancelling subscription</h3>
                <p>Please try again later.</p>
              </>
            )}
          </Modal>
        </div>
      ) : (
        <Plans />
      )}{" "}
    </>
  );
};

export { Subscription };
