import React from "react";
import "./mobile-action-button.css";

const MobileActionButton = ({ text, cancel = false, onClick, disabled }) => {
  return (
    <button
      className="mobileBtn"
      style={{
        border: cancel ? "2px solid var(--green)" : "none",
        color: cancel
          ? "var(--text-color-main)"
          : "var(--background-color-main)",
        backgroundColor: cancel
          ? "var(--background-color-main)"
          : "var(--green)",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export { MobileActionButton };
