import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./nav-bar.css";
import notifications from "../../assets/icons/notifications-outline.svg";
import down from "../../assets/icons/chevron-down.svg";
import profile from "../../assets/icons/person-outline.svg";
import tray from "../../assets/icons/mail-outline.svg";
import settings from "../../assets/icons/settings-outline.svg";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import personWhite from "../../assets/icons/person-white.svg";
import emailWhite from "../../assets/icons/email-white.svg";
import configWhite from "../../assets/icons/gears-white.svg";
import logo from "../../assets/icons/logo-icon.svg";
import goBackIcon from "../../assets/icons/exit-outline.svg";
import texts from "../../texts.json";
import { UserFeedback } from "../user-feedback/UserFeedback";

const NavBar = ({ toggleSideBar, breadcrumbs, sideBarVisible }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showOptions, setShowOptions] = useState(false);
  const handleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setShowOptions(false);
  };

  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 480 && window.innerWidth < 1025
  );

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth > 480 && window.innerWidth < 1025);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const destination = location.pathname === "/login" ? "/" : "/home";

  return (
    <>
      <header className="plataform-header">
        {isTabletView ? (
          <section className="section-title-navbar">
            <img
              style={{ width: 62, height: 62 }}
              src={logo}
              alt="amvi"
              onClick={() => navigate(destination)}
              className="navbar-logo"
            />
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </section>
        ) : (
          <>
            <section className="section-title-navbar">
              <div
                className={"white-logo-container"}
                style={{ width: 52, height: 52 }}
              >
                <img
                  style={{ width: 42, height: 42 }}
                  src={logo}
                  alt="amvi"
                  onClick={() => navigate(destination)}
                />
              </div>
            </section>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </>
        )}
        <section className="section-header">
          <img className="iconTablet" src={personWhite} alt="profile" />
          <img className="iconTablet" src={emailWhite} alt="bandeja" />
          <img className="iconTablet" src={configWhite} alt="config" />
          <img src={notifications} alt="Notifications" />
          <section
            className="profile-header"
            onClick={() => {
              handleOptions();
            }}
          >
            <div className="photo-profile"></div>
            <img src={down} alt="Dropdown" />
          </section>
        </section>
      </header>
      <UserFeedback
        message={"Has iniciado sesion con exito"}
        type={"success"}
      />
      {showOptions && (
        <div className="list-options-profile">
          <div
            className="item-options-profile"
            onClick={() => handleNavigation("/home/profile")}
          >
            <img src={profile} alt="" />
            <label>{texts.components.navBar.profile}</label>
          </div>
          <div className="item-options-profile">
            <img src={tray} alt="" />
            <label>{texts.components.navBar.inbox}</label>
            <span>3</span>
          </div>
          <div
            className="item-options-profile"
            onClick={() =>
              handleNavigation("/home/profile/advanced-configuration")
            }
          >
            <img src={settings} alt="" />
            <label>{texts.components.navBar.configuration}</label>
          </div>
          <div
            className="item-options-profile"
            onClick={() => handleNavigation("/login")}
            style={{
              borderTopColor: "rgb(187, 187, 187)",
              borderTopWidth: 1,
              borderTopStyle: "solid",
            }}
          >
            <img src={goBackIcon} alt="" />
            <label>Cerrar Sesion</label>
          </div>
        </div>
      )}
    </>
  );
};

export { NavBar };
