import React, { useCallback, useState } from "react";
import "./purchase-detail-styles.css";
import rightArrowIcon from "../../assets/icons/right.svg";
import cardIcon from "../../assets/icons/wallet-outline.svg";
import greenCheckIcon from "../../assets/icons/green-check-icon.svg";
import closeIcon from "../../assets/icons/close.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";
import useWindowWidth from "../../hooks/useWindowWidth";
import { MobileActionButton } from "../../components/mobile-action-button/MobileActionButton";
import { ViewTitleAndBackButton } from "../../components/view-title-and-back-button/ViewTitleAndBackButton";
import {
  useGetSubscriptionPlanByPriceIdQuery,
  useGetPaymentMethodDetailsQuery,
  useCreateSubscriptionMutation,
  useGetPaymentMethodBySetupIntentQuery,
  useChangeSubscriptionPlanMutation,
} from "../../features/stripe/stripeApiSlice";
const PurchaseDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const windowWidth = useWindowWidth();
  const queryParams = new URLSearchParams(location.search);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalMessage, setModalMessage] = useState("");

  //Create subscription
  const [createSubscription, { isLoading: creatingSubscription }] =
    useCreateSubscriptionMutation();

  //Change subscription plan
  const [changeSubscriptionPlan, { isLoading: changingPlan }] =
    useChangeSubscriptionPlanMutation();

  // Function to open the modal and set its type
  const openModal = useCallback((type, message) => {
    setModalType(type);
    setModalMessage(message);
    setIsOpen(true);

    if (type === "success") {
      setTimeout(() => {
        navigate("/home/subscription-payment-history");
      }, 3000);
    } else if (type === "error") {
      setTimeout(() => {
        navigate("/home"); //Redirect to home on error
      }, 5000);
    }
  }, []);

  // Function to close the modal and reset its type
  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalType(null);
  }, []);

  // Extraer datos del estado o URL
  const {
    productId,
    plan_name: statePlanName,
    price: statePrice,
    description: stateDescription,
    priceId: statePriceId,
    paymentMethodId,
    validateSubscription,
  } = location.state || {};

  const setupIntentId = queryParams.get("setup_intent");
  const priceId = queryParams.get("priceId");

  // Consultar el método de pago basado en el setupIntentId
  const {
    data: paymentMethodFromSetupIntent,
    isLoading: isSetupIntentLoading,
  } = useGetPaymentMethodBySetupIntentQuery(setupIntentId, {
    skip: !setupIntentId, // Ejecuta solo si setupIntentId está disponible
  });

  // Determinar el método de pago a usar
  const resolvedPaymentMethodId =
    paymentMethodId || paymentMethodFromSetupIntent?.paymentMethod?.id;

  // Consultar los detalles del método de pago
  const { data: paymentMethodDetails, isLoading: isDetailsLoading } =
    useGetPaymentMethodDetailsQuery(resolvedPaymentMethodId, {
      skip: !resolvedPaymentMethodId, // Ejecuta solo si hay un método de pago resuelto
    });

  // Consultar los datos del plan
  const { data: planData, isLoading: isPlanLoading } =
    useGetSubscriptionPlanByPriceIdQuery(priceId, {
      skip: !priceId,
    });

  // Extraer detalles del plan
  const plan_name = planData?.data?.product.name || statePlanName || "N/A";
  const description =
    planData?.data?.product.description ||
    stateDescription ||
    "Sin descripción disponible";
  const monthlyPrice =
    planData?.data?.price?.unit_amount / 100 || statePrice || 0;

  // Handle changing the plan
  const handleChangePlan = async () => {
    try {
      if (!validateSubscription.id) {
        alert("Subscription ID is required to change the plan.");
        return;
      }
      // Payload for changing the plan
      const payload = {
        subscriptionId: validateSubscription.id,
        newProductId: productId,
        paymentMethodId: resolvedPaymentMethodId,
      };

      await changeSubscriptionPlan(payload).unwrap();

      openModal("success"); // Show success modal
    } catch (error) {
      console.error("Error changing plan:", error);
      openModal(
        "error",
        "Error al cambiar el plan. Por favor, inténtelo de nuevo."
      ); // Show error modal
    }
  };

  // Handle subscription creation
  const handleSubscription = async () => {
    if (!resolvedPaymentMethodId && !setupIntentId) {
      alert("Por favor, selecciona un método de pago antes de continuar.");
      return;
    }

    const finalPriceId = statePriceId || priceId;

    const payload = {
      priceId: finalPriceId,
      paymentMethodId: resolvedPaymentMethodId,
    };
    console.log("payload", payload);
    try {
      await createSubscription(payload).unwrap();
      openModal("success"); // Show success modal
    } catch (error) {
      console.error("Error creating subscription:", error);
      openModal(
        "error",
        "Error al crear la suscripción. Inténtalo nuevamente."
      );
    }
  };

  if (
    isPlanLoading ||
    isSetupIntentLoading ||
    isDetailsLoading ||
    changingPlan
  ) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <div className="view-payment-detail">
        <div className="plans-text">
          <label>Nuestros Planes</label>
          <p>Encuentra el plan perfecto para ti y para tu empresa</p>
        </div>
        <div
          className="detail-plan-to-pay"
          style={{ width: windowWidth >= 1300 ? "70%" : "100%" }}
        >
          <div className="plan-to-pay">
            <h4>{plan_name}</h4>
            <label>Incluye</label>
            <ul>
              <li>{description}</li>
              <li>
                Funciones Principales
                <ul>
                  <li>Acceso a plataforma</li>
                  <li>Soporte al cliente</li>
                  <li>Actualizaciones regulares</li>
                </ul>
              </li>
              <li>Soporte {plan_name}</li>
              <li>Reportes mensuales</li>
            </ul>
          </div>
          <div className="payment-details">
            <div className="detail-and-price">
              <label>Detalles</label>
              <div className="cost-and-plan-name">
                <label>1x {plan_name} Mensual</label>
                <label>${monthlyPrice.toFixed(2)} MXN</label>
              </div>
              <div className="monthly-payment">
                <span>Pago mensual de ${monthlyPrice.toFixed(2)}</span>
              </div>
            </div>
            <div className="payment-method">
              <label>Método de Pago</label>
              <button
                onClick={() =>
                  navigate("/home/payment-method", {
                    state: {
                      priceId,
                      plan_name,
                      validateSubscription,
                      productId,
                    },
                  })
                }
              >
                {paymentMethodId || setupIntentId ? (
                  <div className="payment-card-data">
                    <img src={cardIcon} alt="Tarjeta" />
                    <div>
                      <label>
                        <strong>
                          {paymentMethodDetails?.data?.card?.brand} |
                        </strong>{" "}
                        {paymentMethodDetails?.data?.billing_details?.name ||
                          "Titular no especificado"}
                      </label>
                      <div className="card-number-and-expiration-date">
                        <span>.... {paymentMethodDetails?.card?.last4}</span>
                        <span>
                          {paymentMethodDetails?.data?.card?.exp_month}/
                          {paymentMethodDetails?.data?.card?.exp_year}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <label>Seleccionar Método de Pago</label>
                )}
                <img src={rightArrowIcon} alt="right arrow icon" />
              </button>
              <div className="container-for-the-continue-payment-button">
                <MobileActionButton
                  text={
                    validateSubscription ? "Cambiar Plan" : "Continuar con PAGO"
                  }
                  onClick={async () => {
                    if (validateSubscription) {
                      await handleChangePlan();
                    } else {
                      await handleSubscription();
                    }
                  }}
                  disabled={creatingSubscription}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modalContent"
          overlayClassName="modalOverlay"
          contentLabel={
            modalType === "success" ? "Success Modal" : "Error Modal"
          } //A better content Label
        >
          {modalType === "success" ? (
            <img src={greenCheckIcon} alt="checkout" />
          ) : (
            <img src={closeIcon} alt="error" /> // Display the close icon for errors
          )}
          <h3>
            {modalType === "success" ? "Tu compra ha sido registrada" : "Error"}
          </h3>{" "}
          <p>{modalMessage}</p>
        </Modal>
      </div>
      {/* Mobile View */}
      <div className="mobile-view-purchase">
        <ViewTitleAndBackButton title={"Completa tu pago"} />
        <div className="plan-card-for-payment-detail">
          <div className="name-and-price-of-the-plan">
            <label>{plan_name}</label>
            <div className="plan-price-per-month">
              <label>${monthlyPrice.toFixed(2)}</label>
              <span>/mes</span>
            </div>
          </div>
          <div className="subscription-plan-details">
            <label>Incluye</label>
            <ul>
              <li>{description}</li>
              <li>
                Funciones Principales
                <ul>
                  <li>Acceso a plataforma</li>
                  <li>Soporte al cliente</li>
                  <li>Actualizaciones regulares</li>
                </ul>
              </li>
              <li>Soporte {plan_name}</li>
              <li>Reportes mensuales</li>
            </ul>
          </div>
        </div>
        <div className="payment-method-section-in-subscription">
          <label>Método de Pago</label>
          <div className="payment-card-data">
            <img src={cardIcon} alt="Tarjeta" />
            <div>
              <label>
                <strong>{paymentMethodDetails?.card?.brand || "N/A"} |</strong>{" "}
                {paymentMethodDetails?.billing_details?.name || "Sin titular"}
              </label>
              <div className="card-number-and-expiration-date">
                <span>.... {paymentMethodDetails?.card?.last4 || "0000"}</span>
                <span>
                  {paymentMethodDetails?.card?.exp_month}/
                  {paymentMethodDetails?.card?.exp_year}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-for-the-continue-payment-button">
          <MobileActionButton
            text={validateSubscription ? "Cambiar Plan" : "Continuar con PAGO"}
            onClick={async () => {
              if (validateSubscription) {
                await handleChangePlan();
              } else {
                await handleSubscription();
              }
            }}
            disabled={creatingSubscription || changingPlan}
          />
        </div>
      </div>
    </>
  );
};

export { PurchaseDetail };
