import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import ReactDOM from "react-dom/client";
import {
  useRoutes,
  BrowserRouter,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./features/store";
import App from "./App";
import RequireAuth from "./features/auth/RequireAuth";
import { Login } from "./views/account/login/Login";
import { SignUp } from "./views/account/sign-up/SignUp";
import { ForgotPassword } from "./views/account/forgot-password/ForgotPassword";
import { Employees } from "./views/employees/Employees";
import { RegisterEmployee } from "./views/employees/register-employee/RegisterEmployee";
import { EditionEmployee } from "./views/employees/edition-employee/EditionEmployee";
import { EmployeeForm } from "./views/employees/employee-form/EmployeeForm";
import { Home } from "./views/home/Home";
import { Profile } from "./views/home/profile/Profile";
import { LandingPage } from "./views/landing-page/LandingPage";
import { PaperBin } from "./views/paper-bin/PaperBin";
import { Payments } from "./views/payments/Payments";
import { CompanyLocation } from "./views/home/profile/company-location/CompanyLocation";
import { LoginAndSecurity } from "./views/home/profile/login-and-security/LoginAndSecurity";
import { AdvancedConfiguration } from "./views/home/profile/advanced-configuration/AdvancedConfiguration";
import { MyPayments } from "./views/home/profile/my-payments/MyPayments";
import { BillingInformation } from "./views/home/profile/my-payments/billing-information/BillingInformation";
import { PaymentHistory } from "./views/home/profile/my-payments/payment-history/PaymentHistory";
import { PurchaseDetail } from "./views/purchase-detail/PurchaseDetail";
import { PaymentMethod } from "./views/payment-method/PaymentMethod";
import { SubscriptionPaymentHistory } from "./views/subscription-payment-history/SubscriptioPaymentHistory";
import { TripRules } from "./views/home/profile/trips-rules/TripRules";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Subscription } from "./views/subscription/Subscription";
import { PaymentForm } from "./components/payment-form/PaymentForm";

const stripePromise = loadStripe(
  "pk_test_51QK7dpA7xhn46tY572yofv0dyomPk5FVdyLC3BSwicCkzC7mMgVnKocWpl9V0J08b4sY3pGzsBGqXVABHkyIa2fV00jlkMXkOa"
);

const AppRoutes = ({ startTutorial }) => {
  let routes = useRoutes([
    { path: "/", element: <LandingPage /> },
    { path: "/home", element: <Home startTutorial={startTutorial} /> },
    { path: "/home/profile", element: <Profile /> },
    { path: "/home/profile/company-location", element: <CompanyLocation /> },
    { path: "/home/profile/login-and-security", element: <LoginAndSecurity /> },
    {
      path: "/home/profile/advanced-configuration",
      element: <AdvancedConfiguration />,
    },
    {
      path: "/home/profile/advanced-configuration/rules",
      element: <TripRules />,
    },
    {
      path: "/home/profile/billing-information",
      element: <BillingInformation />,
    },
    { path: "/home/profile/payment-history", element: <PaymentHistory /> },
    { path: "/home/profile/my-payments", element: <MyPayments /> },
    { path: "/home/purchase-detail", element: <PurchaseDetail /> },
    { path: "/home/payment-method", element: <PaymentMethod /> },
    {
      path: "/home/new-payment-method",
      element: (
        <Elements stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      ),
    },
    {
      path: "/home/subscription-payment-history",
      element: <SubscriptionPaymentHistory />,
    },
    { path: "/employees", element: <Employees /> },
    { path: "/employees/register-employee", element: <RegisterEmployee /> },
    { path: "/employees/edition-employee", element: <EditionEmployee /> },
    { path: "/employees/manager-employee", element: <EmployeeForm /> },
    { path: "/subscriptions", element: <Subscription /> },
    { path: "/paper-bin", element: <PaperBin /> },
    { path: "/payments", element: <SubscriptionPaymentHistory /> },
    { path: "/login", element: <Login /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    {
      element: <RequireAuth />,
      children: [
        { path: "/home", element: <Home /> },
        { path: "/home", element: <Home /> },
        { path: "/home/profile", element: <Profile /> },
        {
          path: "/home/profile/company-location",
          element: <CompanyLocation />,
        },
        {
          path: "/home/profile/login-and-security",
          element: <LoginAndSecurity />,
        },
        {
          path: "/home/profile/advanced-configuration",
          element: <AdvancedConfiguration />,
        },
        {
          path: "/home/profile/trip-rules",
          element: <TripRules />,
        },
        {
          path: "/home/profile/billing-information",
          element: <BillingInformation />,
        },
        { path: "/home/profile/payment-history", element: <PaymentHistory /> },
        { path: "/home/profile/my-payments", element: <MyPayments /> },
        { path: "/employees", element: <Employees /> },
        { path: "/employees/register-employee", element: <RegisterEmployee /> },
        { path: "/employees/edition-employee", element: <EditionEmployee /> },
        { path: "/employees/manager-employee", element: <EmployeeForm /> },
        { path: "/paper-bin", element: <PaperBin /> },
        { path: "/payments", element: <Payments /> },
      ],
    },
    { path: "*", element: <Navigate to="/login" replace /> }, // Redirect any unknown routes to login
  ]);
  return routes;
};

const Main = () => {
  const navigate = useNavigate();
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [runTutorial, setRunTutorial] = useState(false);
  const location = useLocation();
  const isAuthRoute = ["/login", "/sign-up", "/forgot-password", "/"].includes(
    location.pathname
  );

  const steps = [
    {
      target: ".section-title-navbar",
      content: "Este es el logo visible en todas las pantallas.",
    },
    {
      target: ".menu-button",
      content: "Haz clic aquí para abrir el menú lateral.",
      action: () => navigate("/home"),
    },
    {
      target: ".payment-method",
      content: "Esta es tu metodo de pago.",
      action: () => navigate("/subscriptions"),
    },
    {
      target: ".profile-header",
      content: "Explora las opciones disponibles para tu cuenta",
    },
  ];

  useEffect(() => {
    console.log("runtutorial", runTutorial);
  }, [runTutorial]);

  return (
    <>
      <Joyride
        steps={steps}
        run={runTutorial}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRunTutorial(false);
          }
        }}
      />
      <div
        className={` ${
          isAuthRoute
            ? "full-screen-view"
            : `view-main ${sideBarVisible ? "expanded" : "collapsed"}`
        } `}
      >
        <AppRoutes
          startTutorial={() => {
            setRunTutorial(true);
            console.log("se presiono el boton de tutorial", runTutorial);
          }}
        />
      </div>

      <App
        sideBarVisible={sideBarVisible}
        setSideBarVisible={setSideBarVisible}
      />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
