//forgotPasswordApiSlice.js
import { apiSlice } from "../../api/apiSlice";

export const forgotPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createEmail: builder.mutation({
      query: (body) => ({
        url: "/forgot-password",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useCreateEmailMutation } = forgotPasswordApiSlice;
