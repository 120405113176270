import React from "react";
import "./subscription-payment-history-styles.css";
import useWindowWidth from "../../hooks/useWindowWidth";
import Modal from "react-modal";
import { ViewTitleAndBackButton } from "../../components/view-title-and-back-button/ViewTitleAndBackButton";
import { ItemSubscriptionPurchase } from "../../components/item-subscription-purchase/ItemSubscriptionPurchase";
import { useGetPaymentHistoryQuery } from "../../features/stripe/stripeApiSlice";

const SubscriptionPaymentHistory = () => {
  const windowWidth = useWindowWidth();

  // Fetch payment history data using the query
  const {
    data: paymentHistory,
    isLoading,
    error,
  } = useGetPaymentHistoryQuery();
  console.log(paymentHistory);

  if (isLoading) {
    return <div className="loading-state">Cargando historial de pagos...</div>;
  }

  if (error) {
    return (
      <div className="error-state">
        <div className="payment-history-view-title">Historial de pagos</div>
        <label>
          "¡Aún no tienes pagos registrados! Realiza tu primer pago para ver el
          historial aquí."
        </label>
      </div>
    );
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const isPM = hours >= 12;
    hours = hours % 12 || 12; // Convierte a formato 12 horas

    const period = isPM ? "p.m." : "a.m.";

    return `${day}/${month}/${year} - ${hours}:${minutes} ${period}`;
  }

  return (
    <>
      <div className="view-subscription-payment-history">
        <div className="payment-history-view-title">Historial de pagos</div>
        <table
          className="payment-history-table"
          style={{
            width: "70%",
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            <tr>
              <th>Fecha</th>
              <th style={{ textAlign: "left", paddingLeft: 10 }}>Concepto</th>
              <th>Cantidad</th>
              <th>Factura</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory?.paymentHistory?.map((item, index) => (
              <tr key={index}>
                <td className="table-cells">{formatDate(item.date)}</td>
                <td className="table-cells" style={{ textAlign: "left" }}>
                  {item.name}
                </td>
                <td className="table-cells">{`$${item.amount.toFixed(2)}`}</td>
                <td className="table-cells">
                  {item.pdfUrl ? (
                    <a
                      href={item.pdfUrl}
                      className="paytable-button-download"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Descargar
                    </a>
                  ) : (
                    <span>No disponible</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mobile-payment-history-view">
        <ViewTitleAndBackButton title={"Historial de Pagos"} />
        <div className="mobile-payment-list">
          {paymentHistory?.paymentHistory?.map((item, index) => (
            <div key={index} className="mobile-payment-item">
              <div className="payment-item-date">{item.date}</div>
              <div className="payment-item-name">{item.name}</div>
              <div className="payment-item-amount">{`$${item.amount.toFixed(
                2
              )}`}</div>
              {item.pdfUrl ? (
                <a
                  href={item.pdfUrl}
                  className="paytable-button-download"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descargar
                </a>
              ) : (
                <span>No disponible</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <Modal></Modal>
    </>
  );
};
export { SubscriptionPaymentHistory };
