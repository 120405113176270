// MobileSubscriptionInput.jsx
import React from "react";
import "./mobile-subscription-input.css";

// MobileSubscriptionInput.jsx
const MobileSubscritptionInput = ({
  label,
  value,
  onChange,
  type = "text",
  maxLength,
  placeholder,
  customInput  // Add this prop
}) => {
  return (
    <div className="inputMobileSubs">
      <label>{label}</label>
      {customInput ? customInput : (
        <input
          value={value}
          onChange={onChange}
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
        />
      )}
    </div>
  )
}

export { MobileSubscritptionInput }
