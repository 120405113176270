// employeeApiSlice.js
import { apiSlice } from '../../api/apiSlice';

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    RegisterEmployee: builder.mutation({
      query: (body) => ({
        url: '/employee/register',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Employee'],
    }),
    getEmployeeRole: builder.query({
      query: () => ({
        url: '/employee/role',
        method: 'GET',
      }),
      providesTags: ['Employee'],
    }),
    updateEmployeeRole: builder.mutation({
      query: (data) => ({
        url: '/employee/role',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Employee'],
    }),
  })
});

export const {
  useRegisterEmployeeMutation,
  useGetEmployeeRoleQuery,
  useUpdateEmployeeRoleMutation,
} = employeeApiSlice;
