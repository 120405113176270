import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./employees.css";
import { FabButton } from "../../components/fab-button/FabButton";
import elipsis from "../../assets/icons/ellipsis-horizontal.svg";
import searchIcon from "../../assets/icons/search-outline.svg";
import addIcon from "../../assets/icons/add-circle.svg";
import editIcon from "../../assets/icons/create-outline.svg";
import trashIcon from "../../assets/icons/trash-outline.svg";
import addMobileIcon from "../../assets/icons/add-icon.svg";
import trashImage from "../../assets/images/papelera.png";
import roleImage from "../../assets/images/admin.png";
import approveIcon from "../../assets/icons/approveIcon.svg";
import roleIcon from "../../assets/icons/roleIcon.svg";
import {
  useGetEnterpriseEmployeesQuery,
  useApproveEmployeeMutation,
} from "../../features/enterprise/enterpriseApiSlice";
import {
  useGetEmployeeRoleQuery,
  useUpdateEmployeeRoleMutation,
} from "../../features/employee/employeeApiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import IsAdminIcon from "../../assets/icons/person-circle-outline.svg";

Modal.setAppElement("#root");

const getCSSVariable = (variable) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable);

const estadoColores = {
  Activo: getCSSVariable("--green"),
  Pendiente: "#f0b323",
  Suspendido: getCSSVariable("--suspended-color"),
};

// Componente para cada fila de empleado
const EmployeeRow = ({
  employee,
  onEdit,
  onDelete,
  onApprove,
  onRole,
  isActive,
  onShowOptions,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth < 1025);
  const [employeeRole, setEmployeeRole] = useState(
    useGetEmployeeRoleQuery().data?.isAdmin
  );
  const role = () => {
    if (employeeRole) {
      return <img src={IsAdminIcon} alt="isAdmin" />;
    }
  };
  // Manejador para mostrar/ocultar opciones
  const handleShowOptions = useCallback((employeeId) => {
    setShowOptions((prev) => (prev === employeeId ? null : employeeId));
  }, []);

  // Control de vista responsive
  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth < 1025);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Estado del empleado basado en isPending
  const estado = employee.isPending ? "Pendiente" : "Activo";

  return (
    <tr>
      <td className="id-employee">{employee.employeeId.slice(0, 8)}</td>
      <td className="name-employee-table">{employee.fullName}</td>
      <td>{employee.email}</td>
      <td className="phone-employee">{employee.phone || "No registrado"}</td>
      <td className="trip-employee">No especificado</td>
      <td className="edit-status-delete">
        <div
          className="status"
          style={{ backgroundColor: `${estadoColores[estado]}` }}
        >
          {role} {estado}
        </div>
        <span>
          {isTabletView ? (
            <>
              {employee.isPending && (
                <img
                  onClick={onApprove}
                  src={editIcon}
                  alt="Approve"
                  title="Aprobar empleado"
                />
              )}
              <img onClick={onEdit} src={editIcon} alt="Options" />
              <img onClick={onDelete} src={trashIcon} alt="Options" />
              <img onClick={onRole} src={trashIcon} alt="Options" />
            </>
          ) : (
            <img onClick={onShowOptions} src={elipsis} alt="options" />
          )}
        </span>
        {isActive && (
          <div className="option-box">
            {!employee.isPending && (
              <section
                onClick={() => {
                  handleShowOptions(null);
                  onRole(employee);
                }}
              >
                <img src={roleIcon} alt="Options" />
                <label>Cambiar Rol</label>
              </section>
            )}
            {employee.isPending && (
              <section
                onClick={() => {
                  onApprove(employee);
                  handleShowOptions(null);
                }}
              >
                <img src={approveIcon} alt="Options" />
                <label>Aprobar</label>
              </section>
            )}
            <section
              onClick={() => {
                onEdit();
                handleShowOptions(null);
              }}
            >
              <img src={editIcon} alt="Options" />
              <label>Editar</label>
            </section>
            <section
              onClick={() => {
                onDelete();
                handleShowOptions(null);
              }}
            >
              <img src={trashIcon} alt="Options" />
              <label>Borrar</label>
            </section>
          </div>
        )}
      </td>
    </tr>
  );
};

const Employees = () => {
  const currentSelectedUser = useSelector(selectCurrentUser);

  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [roleModalIsOpen, setRoleModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  // const [approveModalIsOpen, setApproveModalIsOpen] = useState(false);

  // Estado para gestionar qué empleado tiene opciones visibles
  const [activeEmployeeId, setActiveEmployeeId] = useState(null);

  // Obtener datos de empleados
  const { data, isLoading, error } = useGetEnterpriseEmployeesQuery();
  const [approveEmployee, { isLoading: isApproving }] =
    useApproveEmployeeMutation();
  const [updateEmployeeRole, { isLoading: isUpdating }] =
    useUpdateEmployeeRoleMutation();
  const employees = data?.employees || [];

  // Manejo de aprobación de empleado
  const handleApprove = useCallback(
    async (employee) => {
      try {
        console.log("Approving employee:", employee.employeeId); // Add this for debugging
        const response = await approveEmployee(employee.employeeId).unwrap();
        alert("Empleado aprobado exitosamente");
      } catch (error) {
        console.error("Error approving employee:", error);
        alert("Error al aprobar empleado");
      }
    },
    [approveEmployee]
  );

  // Manejo de cambio de rol de empleado
  const handleUpdateEmployeeRole = useCallback(async () => {
    // try {
    const response = await updateEmployeeRole(
      currentSelectedUser.isAdmin
    ).unwrap();
    console.log("response", response);
    closeRoleModal();
    alert("Rol de empleado actualizado exitosamente");
    // } catch (error) {
    //   console.error('Error updating employee role:', error);
    //   alert('Error al actualizar el rol de empleado');
    // }
  }, [updateEmployeeRole]);

  // Navegación entre páginas
  const handleNavigation = useCallback(
    (path, mode = "", employee = null) => {
      navigate(path, {
        state: {
          mode,
          employee,
        },
      });
    },
    [navigate]
  );

  // Manejo del modal
  const openModal = useCallback((employee) => {
    setActiveEmployeeId(null);
    setSelectedEmployee(employee);
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setSelectedEmployee(null);
    setIsOpen(false);
  }, []);

  const openRoleModal = useCallback((employee) => {
    setActiveEmployeeId(null);
    setSelectedEmployee(employee);
    setRoleModalIsOpen(true);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredEmployees = employees.filter((employee) =>
    employee.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const closeRoleModal = useCallback(() => {
    setSelectedEmployee(null);
    setRoleModalIsOpen(false);
  }, []);

  if (isLoading) return <div>Cargando...</div>;
  if (error) return <div>Error al cargar los empleados</div>;

  return (
    <>
      <section className="employees-button-container">
        <div className="employee-search">
          <img src={searchIcon} alt="Search" />
          <input
            type="text"
            placeholder="Buscar empleado"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div
          onClick={() =>
            handleNavigation("/employees/register-employee", "crear")
          }
        >
          <img src={addIcon} alt="Add" />
          <label>Crear Nuevo</label>
        </div>
      </section>

      <section className="employees-table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Teléfono</th>
              <th>
                Preferéncias de
                <br />
                Viaje
              </th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee) => (
              <EmployeeRow
                key={employee.employeeId}
                employee={employee}
                isActive={activeEmployeeId === employee.employeeId}
                onShowOptions={() =>
                  setActiveEmployeeId((prevId) =>
                    prevId === employee.employeeId ? null : employee.employeeId
                  )
                }
                onEdit={() =>
                  handleNavigation(
                    "/employees/edition-employee",
                    "editar",
                    employee
                  )
                }
                onDelete={() => openModal(employee)}
                onApprove={() => handleApprove(employee)}
                onRole={() => openRoleModal(employee)}
              />
            ))}
          </tbody>
        </table>
      </section>

      {/* Modal para confirmar eliminación */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modalContent"
        overlayClassName="modalOverlay"
        contentLabel="Delete Employee Modal"
      >
        <img src={trashImage} alt="Trash" />
        <p>¿Estás seguro de mover</p>
        <h3>{selectedEmployee?.fullName}</h3>
        <p>a la papelera?</p>
        <div>
          <button onClick={closeModal}>Aceptar</button>
          <button onClick={closeModal}>Cancelar</button>
        </div>
      </Modal>

      {/* Modal para confirmar cambio de rol */}
      <Modal
        isOpen={roleModalIsOpen}
        onRequestClose={closeRoleModal}
        className="modalContent"
        overlayClassName="modalOverlay"
        contentLabel="Delete Employee Modal"
      >
        <img src={roleImage} alt="Trash" />
        <p>¿Estás seguro de cambiar el rol de</p>
        <h3>{selectedEmployee?.fullName}</h3>
        <p>{selectedEmployee?.isAdmin ? "Empleado" : "Administrador"}?</p>
        <div>
          <button onClick={() => handleUpdateEmployeeRole()}>Aceptar</button>
          <button onClick={closeRoleModal}>Cancelar</button>
        </div>
      </Modal>

      <FabButton
        imgSrc={addMobileIcon}
        onClick={() => handleNavigation("/employees/manager-employee", "crear")}
      />
    </>
  );
};

export { Employees };
