// features/employee/employeeSlice.js
// Manejo del estado del empleado
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employeeId: null,
  employeeRole: null,
};

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployee: (state, action) => {
      state.employeeId = action.payload;
    },
    setEmployeeRole: (state, action) => {
      state.employeeRole = action.payload;
    },
  },
});

export const { setEmployee, setEmployeeRole } = employeeSlice.actions;
export default employeeSlice.reducer;
export const selectCurrentEmployee = (state) => state.employee.employeeId;
export const selectCurrentEmployeeRole = (state) => state.employee.employeeRole;