// stripeApiSlice.js
import { apiSlice } from "../../api/apiSlice";

export const stripeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEnterpriseStripeId: builder.query({
      query: () => "/stripe/enterprise-stripe-id",
      keepUnusedDataFor: 60,
      providesTags: ["Stripe"],
    }),
    addCard: builder.mutation({
      query: (paymentMethodId) => ({
        url: "/stripe/add-card",
        method: "POST",
        body: { paymentMethodId },
      }),
      invalidatesTags: ["Stripe"],
    }),
    getSubscriptionPlans: builder.query({
      query: () => "/stripe/subscription-plans",
      keepUnusedDataFor: 60,
      providesTags: ["Stripe"],
    }),
    getCards: builder.query({
      query: () => "/stripe/cards",
      keepUnusedDataFor: 60,
      providesTags: ["Stripe"],
    }),
    setDefaultCard: builder.mutation({
      query: (paymentMethodId) => ({
        url: "/stripe/default-card",
        method: "POST",
        body: { paymentMethodId },
      }),
      invalidatesTags: ["Stripe"],
    }),
    removeCard: builder.mutation({
      query: (paymentMethodId) => ({
        url: `/stripe/cards/${paymentMethodId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Stripe"],
    }),
    createSubscription: builder.mutation({
      query: (payload) => ({
        url: "/stripe/create-subscription",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Stripe"],
    }),
    createSetupIntent: builder.mutation({
      query: (payload) => ({
        url: "/stripe/create-setup-intent",
        method: "POST",
        body: payload,
      }),
    }),
    getSubscriptionPlanByPriceId: builder.query({
      query: (priceId) => ({
        url: `/stripe/subscriptions/${priceId}`,
        method: "GET",
      }),
    }),
    getPaymentMethodDetails: builder.query({
      query: (paymentMethodId) => `/stripe/payment-methods/${paymentMethodId}`,
    }),
    getSubscription: builder.query({
      query: () => ({
        url: "/stripe/subscription",
        method: "GET",
      }),
    }),
    getPaymentMethodBySetupIntent: builder.query({
      query: (setupIntentId) => ({
        url: `/stripe/getPaymentMethod/${setupIntentId}`,
        method: "GET",
      }),
    }),
    cancelSubscription: builder.mutation({
      query: (subscriptionId) => ({
        url: "/stripe/cancel-subscription",
        method: "POST",
        body: { subscriptionId },
      }),
    }),
    getDefaultPaymentMethod: builder.query({
      query: () => ({
        url: "/stripe//getDefaultPaymentMethod",
        method: "GET",
      }),
    }),
    setDefaultPaymentMethod: builder.mutation({
      query: (paymentMethodId) => ({
        url: "/stripe/setDefaultPaymentMethod",
        method: "POST",
        body: { paymentMethodId },
      }),
    }),
    changeSubscriptionPlan: builder.mutation({
      query: (paymentMethodId) => ({
        url: "/stripe/changeSubscriptionPlan",
        method: "POST",
        body: { paymentMethodId },
      }),
    }),
    getPaymentHistory: builder.query({
      query: () => ({
        url: "/stripe/getPaymentHistory",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetEnterpriseStripeIdQuery,
  useGetSubscriptionPlansQuery,
  useAddCardMutation,
  useGetCardsQuery,
  useSetDefaultCardMutation,
  useRemoveCardMutation,
  useCreateSubscriptionMutation,
  useCreateSetupIntentMutation,
  useGetSubscriptionPlanByPriceIdQuery,
  useGetPaymentMethodDetailsQuery,
  useGetSubscriptionQuery,
  useGetPaymentMethodBySetupIntentQuery,
  useCancelSubscriptionMutation,
  useGetDefaultPaymentMethodQuery,
  useSetDefaultPaymentMethodMutation,
  useChangeSubscriptionPlanMutation,
  useGetPaymentHistoryQuery,
} = stripeApiSlice;
