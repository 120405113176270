import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./home.css";
import profile from "../../assets/icons/person-outline.svg";
import employe from "../../assets/icons/people-outline.svg";
import config from "../../assets/icons/settings-outline.svg";
import warning from "../../assets/images/warning.png";

const Home = ({ startTutorial }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const initTutorial = () => {
    if (startTutorial) {
      startTutorial(true);
    } else {
      console.error("startTutorial no está definido");
    }
  };

  return (
    <>
      <section className="home-welcome">
        <h2>¡Bienvenido a amvi!</h2>
        <p>Comparte tu viaje, reduce tu huella</p>
        <button onClick={initTutorial}>Iniciar Tutorial</button>
      </section>
      <section className="quick-actions">
        <p>Acciones Rapidas</p>
        <div>
          <button onClick={() => handleNavigation("/home/profile")}>
            <img src={profile} alt="profile" />
            <label>Mi Perfil</label>
          </button>
          <button
            onClick={() => handleNavigation("/employees/register-employee")}
          >
            <img src={employe} alt="employe" />
            <label>Empleados</label>
          </button>
          <button
            onClick={() =>
              handleNavigation("/home/profile/advanced-configuration")
            }
          >
            <img src={config} alt="config" />
            <label>Ajustes</label>
          </button>
        </div>
      </section>
      <section className="summary-statistics" onClick={() => openModal()}>
        <div className="statistics-head">
          <label>Estadísticos</label>
          <span>Julio 31 - Junio 31 2024</span>
        </div>
        <div className="statistics-info">
          <div>
            Por el momento
            <br />
            no hay
            <br />
            información
            <br />
            disponible
          </div>
          <div>
            Por el momento
            <br />
            no hay
            <br />
            información
            <br />
            disponible
          </div>
        </div>
        <div className="statistics-info-movil" onClick={() => openModal()}>
          <div>
            Por el momento no hay información
            <br />
            disponible.
            <br />
            <br />
            Seleccione un Plan de Suscripción
          </div>
          <div>
            Por el momento no hay información
            <br />
            disponible.
            <br />
            <br />
            Seleccione un Plan de Suscripción
          </div>
          <div>
            Por el momento no hay información
            <br />
            disponible.
            <br />
            <br />
            Seleccione un Plan de Suscripción
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modalContent"
        overlayClassName="modalOverlay"
        contentLabel="Example Modal"
      >
        <img
          src={warning}
          alt="warning"
          style={{ width: "118.19px", height: "194.7px" }}
        />
        <h3>¡ATENCIÓN!</h3>
        <p>
          Necesitas una Suscripción para ver este
          <br />
          contenido.
          <br />
          <br />
          Selecciona el mejor Plan para tu Equipo
        </p>
        <div>
          <button
            onClick={() => {
              handleNavigation("/home/plans");
            }}
          >
            Ver Planes
          </button>
          <button>Cancelar</button>
        </div>
      </Modal>
    </>
  );
};

export { Home };
