import React, { useState } from "react";
import "./trip-rules-styles.css";
import { SettingsSectionTitle } from "../../../../components/settings-section-title/SettingsSectionTitle";
import { ToggleButton } from "../../../../components/toogle-button/ToogleButton";
import { GreenBorderButton } from "../../../../components/green-border-button/GreenBorderButton";
import { useGetEnterpriseTripsRulesQuery } from "../../../../features/enterprise/enterpriseApiSlice";
import { data } from "@remix-run/router";

const TripRules = () => {
  const {
    data: tripRules,
    isLoading,
    error,
  } = useGetEnterpriseTripsRulesQuery();

  const rulesFake = [
    {
      label: "Se permite viajar con otras empresas.",
      enable: false,
    },
    {
      label:
        "Los viajes entre empresas están limitados a un máximo de 4 personas por vehículo, respetando las normativas de seguridad vial.",
      enable: false,
    },
    {
      label:
        "Todos los viajes deberán estar cubiertos por un seguro proporcionado por la empresa.",
      enable: false,
    },
  ];

  // // Define el JSON como un objeto de JavaScript
  // const data = {
  //     "tripRules": [
  //         {
  //             "enterpriseOptions": {
  //                 "language": "spanish"
  //             }
  //         }
  //     ]
  // };

  // // Acceso al valor de "language"
  //   const language = tripRules[0].enterpriseOptions.language;

  //   console.log("language", language); // Output: "spanish"

  // const rules = tripRules?.data?.tripRules;

  // console.log("reglas", rules);

  return (
    <div className="trips-rules-container">
      <SettingsSectionTitle label={"Reglas de Viaje"} />
      <div className="trips-rules-list-container">
        {rulesFake.map((rule, index) => (
          <div className="rule-container" key={index}>
            <label>{rule.label}</label>
            <ToggleButton initialState={rule.enable} />
          </div>
        ))}
      </div>
      <div className="save-rules-button-container">
        <GreenBorderButton text={"Guardar"} border={true} />
      </div>
    </div>
  );
};

export { TripRules };
